define("ember-svg-jar/inlined/clark-contracts-assets--trending-wrench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.5 3.75a.75.75 0 00-.75-.75h-5a.75.75 0 000 1.5h3.19L12.5 9.94l-2.22-2.22a.75.75 0 00-1.06 0l-6.5 6.5a.75.75 0 101.06 1.06l5.97-5.97 2.22 2.22a.75.75 0 001.06 0L19 5.56v3.19a.75.75 0 001.5 0v-5zm-.52 8.083c.25-.25.165-.673-.181-.749a4.001 4.001 0 00-4.54 5.47l-3.741 3.742a1.5 1.5 0 002.121 2.121l3.742-3.741a4.001 4.001 0 005.47-4.54c-.076-.347-.499-.432-.75-.182L20.358 15.7a1.5 1.5 0 01-2.122-2.121l1.745-1.745z\" fill=\"#2F3341\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});