define("ember-svg-jar/inlined/camera-sparkles-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_7392_51)\"><path d=\"M16.354 6.146a2.844 2.844 0 01.685 1.114l.448 1.377a.543.543 0 001.026 0l.448-1.377a2.837 2.837 0 011.798-1.796l1.378-.448a.544.544 0 000-1.025l-.028-.007-1.378-.448a2.84 2.84 0 01-1.798-1.796L18.486.363a.544.544 0 00-1.027 0l-.448 1.377-.011.034a2.835 2.835 0 01-1.759 1.762l-1.378.448a.544.544 0 000 1.025l1.378.448c.42.14.8.376 1.113.689zm7.163 3.819l.766.248.015.004a.303.303 0 01.147.46.302.302 0 01-.147.11l-.765.248a1.578 1.578 0 00-1 .998l-.248.765a.302.302 0 01-.57 0l-.249-.764a1.575 1.575 0 00-.999-1.002l-.765-.249a.303.303 0 01-.147-.46.302.302 0 01.147-.11l.765-.248a1.576 1.576 0 00.984-.999l.249-.764a.302.302 0 01.57 0l.249.764a1.575 1.575 0 00.998.999zM10.622 4.003h1.959a1.546 1.546 0 01.95-.962l1.391-.453.05-.017a2.249 2.249 0 00-.547-.068h-3.803a2.25 2.25 0 00-1.917 1.073L7.83 5H5.75A3.25 3.25 0 002.5 8.25v9.5A3.25 3.25 0 005.75 21h13.5a3.25 3.25 0 003.25-3.25v-3.852c-.406.164-.89.118-1.252-.137a1.304 1.304 0 01-.248-.229v4.218a1.75 1.75 0 01-1.75 1.75H5.75A1.75 1.75 0 014 17.75v-9.5c0-.966.784-1.75 1.75-1.75h2.5a.75.75 0 00.64-.358l1.093-1.781.065-.09a.75.75 0 01.574-.268zM17 12.5a4.5 4.5 0 10-9 0 4.5 4.5 0 009 0zm-7.5 0a3 3 0 116 0 3 3 0 01-6 0z\" fill=\"#fff\"/></g><defs><clipPath id=\"clip0_7392_51\"><path fill=\"#fff\" transform=\"translate(.5)\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});