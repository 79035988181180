define("ember-svg-jar/inlined/clark-contracts-assets--money-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 7a3 3 0 100 6 3 3 0 000-6zm-1.5 3a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm-7-3.75A2.25 2.25 0 013.75 4h12.5a2.25 2.25 0 012.25 2.25V11H17V8.5h-.75A2.25 2.25 0 0114 6.25V5.5H6v.75A2.25 2.25 0 013.75 8.5H3v3h.75A2.25 2.25 0 016 13.75v.75h7.5V16H3.75a2.25 2.25 0 01-2.25-2.25v-7.5zm2.25-.75a.75.75 0 00-.75.75V7h.75a.75.75 0 00.75-.75V5.5h-.75zM17 7v-.75a.75.75 0 00-.75-.75h-.75v.75c0 .414.336.75.75.75H17zM3 13.75c0 .414.336.75.75.75h.75v-.75a.75.75 0 00-.75-.75H3v.75zm.901 3.75H13.5V19h-7c-1.11 0-2.08-.603-2.599-1.5zM21.5 11V9c0-1.11-.603-2.08-1.5-2.599V11h1.5zM16 12a1.5 1.5 0 00-1.5 1.5v8A1.5 1.5 0 0016 23h5a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0021 12h-5zm.5 4.5h1a.5.5 0 010 1h-1a.5.5 0 010-1zm3 0h1a.5.5 0 010 1h-1a.5.5 0 010-1zm-3 2h1a.5.5 0 010 1h-1a.5.5 0 010-1zm3 0h1a.5.5 0 010 1h-1a.5.5 0 010-1zm-3 2h1a.5.5 0 010 1h-1a.5.5 0 010-1zm3 0h1a.5.5 0 010 1h-1a.5.5 0 010-1zM16 14a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-1z\" fill=\"#2F3341\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});