define("ember-svg-jar/inlined/clark-contracts-assets--arrow-sync-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 8.25a.75.75 0 011.5 0v3.25a.75.75 0 01-.75.75H14a.75.75 0 010-1.5h1.27A3.502 3.502 0 0012 8.5c-1.093 0-2.037.464-2.673 1.23a.75.75 0 11-1.154-.96C9.096 7.66 10.463 7 12 7c1.636 0 3.088.785 4 2v-.75zM8 15v.75a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75H10a.75.75 0 010 1.5H8.837a3.513 3.513 0 005.842.765.75.75 0 111.142.972A5.013 5.013 0 018 15zm4-13C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm8.5 10a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});