define("ember-svg-jar/inlined/clark-contracts-assets--tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.416 8.212a.939.939 0 011.4.001c.38.413.38 1.079-.002 1.491l-6.56 7.084a.939.939 0 01-1.392.006L6.192 12.9a1.114 1.114 0 01-.012-1.49.939.939 0 011.399-.015l2.97 3.153 5.867-6.335z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});