define("ember-svg-jar/inlined/clark-contracts-assets--piggy-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.581 4.09c.53.365 1.037.606 1.454.66a5.024 5.024 0 00-.47 1.439c-.673-.148-1.311-.503-1.836-.865-.26-.179-.504-.37-.729-.557V7a.75.75 0 01-.47.696l-.006.002a2.37 2.37 0 00-.166.074c-.117.056-.287.14-.486.254A6.45 6.45 0 005.53 9.03c-.453.454-.733 1.063-.89 1.536-.203.607-.719 1.166-1.453 1.288a.224.224 0 00-.187.221v1.753c0 .165.12.307.283.334.624.104 1.115.511 1.392 1.006.251.45.698 1.144 1.355 1.802.511.51 1.106.838 1.585 1.039a5.25 5.25 0 00.774.254h.005v.001A.75.75 0 019 19v1.25c0 .138.112.25.25.25h1.25A1.5 1.5 0 0112 19h2a1.5 1.5 0 011.5 1.5h1.25a.25.25 0 00.25-.25V18c0-.283.16-.542.412-.67l.013-.006a3.907 3.907 0 00.354-.228c.243-.176.572-.45.902-.834C19.333 15.501 20 14.294 20 12.5c0-1.007-.188-1.82-.509-2.488.358-.474.628-1.009.798-1.575.1.135.194.276.282.423.604.998.929 2.203.929 3.64 0 2.206-.833 3.75-1.68 4.738a6.737 6.737 0 01-1.32 1.183v1.83A1.75 1.75 0 0116.75 22H15.5a1.5 1.5 0 01-1.5-1.5h-2a1.5 1.5 0 01-1.5 1.5H9.25a1.75 1.75 0 01-1.75-1.75v-.684a7.054 7.054 0 01-.464-.175A6.457 6.457 0 014.97 18.03a9.863 9.863 0 01-1.605-2.13.49.49 0 00-.329-.259A1.838 1.838 0 011.5 13.828v-1.753c0-.843.61-1.562 1.44-1.7.087-.015.216-.101.277-.284.192-.576.565-1.434 1.253-2.121a7.944 7.944 0 011.658-1.246c.136-.078.262-.145.372-.201V3.67c0-.938 1.13-1.323 1.74-.716.33.329.81.768 1.341 1.134zm2.989 4.758c-.49-.203-.904-.588-1.002-1.11a4.002 4.002 0 017.627-2.269 4.001 4.001 0 01-1.436 4.833c-.438.3-1.003.28-1.493.077l-3.696-1.53zm4.394.18a2.502 2.502 0 00-2.42-4.338A2.502 2.502 0 0013.03 7.4l.021.015a.535.535 0 00.092.048l3.696 1.53a.538.538 0 00.124.036zM8.5 10a1 1 0 11-2 0 1 1 0 012 0z\" fill=\"#2F3341\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});