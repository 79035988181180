define("ember-svg-jar/inlined/clark-contracts-assets--rocket-filled-fins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".5\" width=\"64\" height=\"64\" rx=\"32\" fill=\"#F5F6F9\"/><path d=\"M34.333 24.076a4.336 4.336 0 116.132 6.132 4.336 4.336 0 01-6.132-6.132zm4.293 1.84a1.734 1.734 0 10-2.453 2.452 1.734 1.734 0 002.453-2.452zm7.224-10.371a4.77 4.77 0 013.141 3.14l.358 1.152a11.707 11.707 0 01-2.9 11.752l-1.732 1.731a6.066 6.066 0 01-.568 7.922l-2.155 2.155a1.3 1.3 0 01-1.84 0l-2.757-2.757-.306.306a3.035 3.035 0 01-4.293 0l-.844-.844-1.407 2.411a1.3 1.3 0 01-2.044.264l-6.745-6.745a1.3 1.3 0 01.252-2.036l2.401-1.437-.816-.816a3.035 3.035 0 010-4.292l.312-.312-2.76-2.76a1.3 1.3 0 010-1.84l2.155-2.154a6.066 6.066 0 017.925-.567l1.727-1.727a11.707 11.707 0 0111.748-2.903l1.148.357zm.657 3.911a2.168 2.168 0 00-1.428-1.427l-1.148-.356a9.106 9.106 0 00-9.137 2.258l-9.36 9.36a.434.434 0 000 .612l9.204 9.204c.17.17.444.17.613 0l9.358-9.357a9.106 9.106 0 002.256-9.141l-.358-1.153zM23.29 43.091a1.3 1.3 0 00-1.84-1.84l-4.292 4.292a1.3 1.3 0 101.84 1.84l4.292-4.292zm-3.372-5.213a1.3 1.3 0 010 1.84l-1.84 1.84a1.3 1.3 0 11-1.84-1.84l1.84-1.84a1.301 1.301 0 011.84 0zm6.741 8.589a1.3 1.3 0 10-1.84-1.84l-1.835 1.836a1.3 1.3 0 101.84 1.84l1.835-1.836z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "65",
      "height": "64",
      "viewBox": "0 0 65 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});